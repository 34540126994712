import React, { useState, useEffect } from 'react';
import {
        Card,
        Col,
        Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Base from '../Layout/Base';
import useToken from '../Authentication/Token';
import axios from 'axios';


export default function ListModules() {
    const { removeToken } = useToken();

    const [productionList, setProductionList] = useState([]);
    const [developmentList, setDevelopmentList] = useState([]);
    const [hiddenList, setHiddenList] = useState([]);

    function fetchModules() {
        axios({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            url: '/api/modules'
        })
        .then(response => {
            // organize modules into production, development, and hidden lists
            for (let module of response.data.modules) {
                if (module.status === 'production') {
                    setProductionList(productionList => [...productionList, module]);
                } else if (module.status === 'development') {
                    setDevelopmentList(developmentList => [...developmentList, module]);
                } else if (module.status === 'private') {
                    setHiddenList(hiddenList => [...hiddenList, module]);
                }
            }
        })
        .catch(error => {
            console.log(error.response)
            if ( error.response.status === 401 ) {
                alert("Your session has expired. Please log in again.")
                removeToken()
            }
            else if ( error.response.status === 422 ) {
                alert("You are not authorized to view this page. Please log in.")
                window.location.href = '/'
            }
            alert(error.response.data.msg)
        });
    }
    useEffect(() => {
        fetchModules();
    }, []);
    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = (moduleId, listName) => {

        setExpanded(!expanded);
    }
    return (
        <Base>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3>TLDR Modules</h3>
                        <hr />
                        <p>Click on a module to view its content</p>
                        { productionList.length > 0 && <h4>Production Modules</h4> }
                        < hr/>
                        <Row>
                            { productionList.map(module => (
                                <Col lg={4} key={module.id}>
                                    <Link style={{ textDecoration: 'none' }} to={`/start/${module.name}`}>
                                        <Card style={{margin:"10px"}}>
                                        <div className='moduleContainer'>

                                            <div className='moduleTitle'>
                                                <Card.Title><b>{module.name}</b></Card.Title>
                                            </div>
                                            <Card.Body>
                                                <div className='moduleDescription'>
                                                    {module.description}
                                                    {/* { expanded ? module.description : module.description.slice(0, 100) } */}
                                                </div>
                                                {/* <p className='moduleButton' onClick={toggleExpansion} variant='link'>
                                                    { expanded ? 'Show less' : 'Show more' }
                                                </p> */}
                                            </Card.Body>
                                        </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                
                        { hiddenList.length > 0 && <h4>Private Modules</h4> }
                        <hr />
                
                        <Row>
                            { hiddenList.map(module => (
                                <Col lg={4} key={module.id}>
                                    <Link style={{ textDecoration: 'none' }} to={`/start/${module.name}`}>
                                        <Card style={{margin:"10px"}}>
                                        <div className='moduleContainer'>
                                            <div className='moduleTitle'>
                                                <Card.Title><b>{module.name}</b></Card.Title>
                                            </div>
                                            <Card.Body>
                                                <div className='moduleDescription'>
                                                    {module.description}
                                                </div>
                                            </Card.Body>
                                        </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                
                        { developmentList.length > 0 && <h4>Development Modules</h4> }
                        <hr />
                        <Row>
                            { developmentList.map(module => (
                                <Col lg={4} key={module.id}>
                                     <Link style={{ textDecoration: 'none' }} to={`/start/${module.name}`}>
                                        <Card style={{margin:"10px"}}>
                                        <div className='moduleContainer'>
                                            <div className='moduleTitle'>
                                                <Card.Title><b>{module.name}</b></Card.Title>
                                            </div>
                                            <Card.Body>
                                                <div className='moduleDescription'>
                                                    {module.description}
                                                </div>
                                        
                                            </Card.Body>
                                        </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
             
                    </div>
                </div>
            </div>
        </Base>
    )
}