import axios from 'axios';
import useEffect, { useState } from 'react';

export default function useToken() {
    const [token, setToken] = useState(getToken())
    const [isAdmin, setIsAdmin] = useState(false)
    const [user, setUser] = useState(getUser()) 
    const [apiToken, setApiToken] = useState('')
    const [sitekey, setSitekey] = useState('')

    function getToken() {
        const token = localStorage.getItem('token')
        return token && token
    }
    function getUser() {
        const user = localStorage.getItem('user')
        return user && user
    }

    function saveToken(userToken, userData, isAdmin) {
        localStorage.setItem('token', userToken)
        localStorage.setItem('user', userData['firstName'])
        setIsAdmin(isAdmin)
    }

    function removeToken() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/auth/login'
    }

    async function verifyToken() {
        await axios({
            method: "GET",
            url: "/api/auth/verify",
            headers : { Authorization : "Bearer " + token}
        })
        .then(response => { 
            if (response.status === 201) {
                console.log(response.data)
                // jwt token is still valid
                setIsAdmin(response.data.isAdmin)
                fetchapiToken()
            }
            else if (response.status === 200 ) {
                // jwt token is renewed
                setToken(response.data.token)
                setIsAdmin(response.data.isAdmin)
                localStorage.setItem('token', response.data.token)
                fetchapiToken()
            } 
        })
        .catch(error => {
            // jwt token is invalid
            removeToken()
        })
    }


  function fetchapiToken() {
    axios({
      method: 'get',
      url: '/api/auth/get_api_token',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
        setApiToken(response.data.apiToken)
    })
    .catch((error) => {
        console.log(error)
    })
  }
  async function getSiteKey() {
    axios({
      method: 'get',
      url: '/api/auth/get_site_key',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
        setSitekey(response.data.sitekey)
    })
    .catch((error) => {
        console.log(error)
    })
    }

    return {
        saveToken,
        removeToken,
        verifyToken,
        getSiteKey,
        sitekey,
        apiToken,
        token,
        isAdmin,
        user
    }
}
