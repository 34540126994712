import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { CDBTable, CDBTableHeader, CDBTableBody, CDBContainer } from 'cdbreact';
import Base from '../Layout/Base';



export default function JobDetail() {
    const { jobId } = useParams()
    const [info, setInfo] = useState({})
    const [files, setFiles] = useState([])
    const [currentPath, setCurrentPath] = useState('')
    const [resultFiles, setResultFiles] = useState([])
    
    const [jobStatus, setJobStatus] = useState('')

    const [jobLog, setJobLog] = useState('')
    const logRef = useRef(null)
    
    const fetchFiles = async (jobPath) => {
        console.log(localStorage.getItem('token'))
        try {
            const response = await axios ({
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
                url: `/api/results/${jobId}/${jobPath ? jobPath : ''}`
            })
            const data = await response.data
            setFiles(data.folder.others)
            setInfo(data.info)
            setJobStatus(data.info.status)
            setResultFiles(data.folder.results)
            setCurrentPath(jobPath ? jobPath : '')
            setJobLog(data.info.log)
        } catch (error) {
            if ( error.response.status === 401 ) {
                alert("Your session has expired. Please log in again.")
                localStorage.removeItem('token')
                window.location.href = '/auth/login'
            }
            else if ( error.response.status === 422 ) {
                alert("You are not authorized to view this page. Please log in.")
                window.location.href = '/auth/login'
            }
            alert(error.response.data.msg)
            window.location.href = '/jobs'
        }
    }
    const handleFolderClick = (folderName) => {
        const newPath = currentPath ? `${currentPath}/${folderName}` : folderName
        if (newPath === '') {
            fetchFiles()
            return
        }
        fetchFiles(newPath || '')
    }
    const handleBackClick = () => {
        const newPath = currentPath.split('/').slice(0, -1).join('/')
        fetchFiles(newPath)
    }
    function handleResultItemClick (item) {
        const downloadUrl = `/api/results/${jobId}/results/${item.name}`
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        const token = localStorage.getItem('token')
        const headers = new Headers()
        headers.append('Authorization', `Bearer ${token}`)
        fetch(downloadUrl, {
            headers: headers
        })
        .then(response => response.blob())
        .then(blob => {
            const blobUrl = URL.createObjectURL(blob)
            anchor.href = blobUrl
            anchor.download = item.name
            anchor.click()
            URL.revokeObjectURL(blobUrl)
        })
        .catch(error => {
            console.error("Error downloading file", error)
        })
        anchor.remove()
    }
    function handleItemClick (item) {
        if (item.type === 'directory') {
            handleFolderClick(item.name)
        }
        else {
        
            const downloadUrl = `/api/results/${jobId}/${currentPath}/${item.name}`
            const anchor = document.createElement('a')
            anchor.href = downloadUrl
            const token = localStorage.getItem('token')
            const headers = new Headers()
            headers.append('Authorization', `Bearer ${token}`)
            fetch(downloadUrl, {
                headers: headers
            })
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob)
                anchor.href = blobUrl
                anchor.download = item.name
                anchor.click()
                URL.revokeObjectURL(blobUrl)
            })
            .catch(error => {
                console.error("Error downloading file", error)
            })
            anchor.remove()


        }
    }
    // Fetch job log and status when job is running
    function fetchJobStatus() { 
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
            url: `/api/results/${jobId}/check`
        })
        .then(response => {
            if (response.data.status === 'Completed') {
                window.location.reload()
            }
            setJobStatus(response.data.status)
            setJobLog(response.data.log)
        })
        .catch(error => {
            console.error("Error fetching job status", error)
        }
        )
    }
    useEffect(() => {
        fetchFiles()
        const intervalId = setInterval(() => {
            if (jobStatus === 'Running' || jobStatus === 'Submitted') {
                fetchJobStatus()
            }
        }, 1000)
        return () => clearInterval(intervalId)
    }, [jobStatus])

    useEffect(() => {
        if (logRef.current) {
            logRef.current.scrollTop = logRef.current.scrollHeight
        }
    }, [jobLog])
    
    return (
        <Base>
            <Row>
                <Col>
                    <Card>
                    <Card.Header>Info</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <p>Job ID</p>
                            </Col>
                            <Col>
                                {info.id}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Job Status</p>
                            </Col>
                            <Col>
                                {jobStatus}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Last Updated</p>
                            </Col>
                            <Col>
                                {info.last_updated}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>Memo</p>
                            </Col>
                            <Col>
                                {info.memo}
                            </Col>
                        </Row>
                    </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='resultCard'>
                        <Card.Header>Result</Card.Header>
                        <Card.Body>
                            {
                            ( jobStatus === 'Completed' ? (
                                <CDBTable striped hover>
                                    <CDBTableHeader>
                                        <tr>
                                            <th>Name</th>
                                            <th>Size</th>
                                        </tr>
                                    </CDBTableHeader>
                                    <CDBTableBody>
                                    {resultFiles.map((item, index) => (
                                        <tr key={index} onClick={() => handleResultItemClick(item)}>
                                        <td>{item.name}</td>
                                        <td>{item.size}</td>
                                        </tr>
                                    ))}
                                    </CDBTableBody>
                                </CDBTable>

                            ) : ( jobStatus === 'Running' || jobStatus === 'Submitted' ? (
                                <p>Job is still running. Please check back later.</p>
                            ) : ( jobStatus === 'Failed' ? (
                                <p>Job failed. Please check the log for more details.</p>
                            
                            ) : (
                                <p>No result file.</p>
                            ))
                            )   
                            )}
                      

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
           
            <br />
            <h3>Job Log</h3>
            <div style={{border:"1px"}}>
                <pre ref={logRef} style={{ height: '200px', overflow: 'auto'}}>{jobLog}</pre>

            </div>

               
          
            <br />
          
            <br />
            <h3>Input & Supporting Files</h3>
            <h5>Current Path : {currentPath ? "/" + currentPath : "/"}</h5>
            <Button onClick={handleBackClick}>Back</Button>
            <CDBContainer>
                <CDBTable striped hover>
                    <CDBTableHeader>
                        <tr>
                            <th>Name</th>
                            <th>Last Modified</th>
                            <th>Size</th>
                        </tr>
                    </CDBTableHeader>
                    <CDBTableBody>
                    {files.map((item, index) => (
                        <tr style={{ cursor : "pointer" }} key={index} onClick={() => handleItemClick(item)}>
                        <td>{item.type === 'directory' ? <i className="bi bi-folder">&nbsp;<span>{item.name}</span></i> : <i className="bi bi-file-earmark">&nbsp;<span>{item.name}</span></i>}</td>
                        <td>{item.last_modified}</td>
                        <td>{item.size}</td>
                        </tr>
                    ))}
                    </CDBTableBody>
                </CDBTable>
            </CDBContainer>
        
        </Base>

    )
}